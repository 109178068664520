<ion-row>
  <ion-col size="12">
    <h3 class="column-header">Survey results</h3>
  </ion-col>
  <ion-col size-md="3" size="12" *ngIf="!isLoading">
    <ion-card class="outline-card report-card">
      <span class="report-stat">{{ totalResponses > 0 ? surveyScore : 'N/A' }}</span>
      <div class="report-title">{{ survey.type === 'nps' ? 'NPS score' : 'CSAT score' }}</div>
    </ion-card>
  </ion-col>
  <ion-col size-md="3" size="12">
    <ion-card class="outline-card report-card">
      <span class="report-stat">{{totalResponses}}</span>
      <div class="report-title">Total responses</div>
    </ion-card>
  </ion-col>
  <ion-col size="12">
    <ion-card class="outline-card">
      <ngx-datatable
        class="material fullscreen"
        [columnMode]="ColumnMode.flex"
        [limit]="100"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [rows]="surveyResponses"
        rowHeight="auto"
        [loadingIndicator]="isLoading"
      >
        <ngx-datatable-column name="Score" prop="value" [flexGrow]="1" [sortable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              <ion-badge color="light">{{row.value}}</ion-badge>
              <div class="onboarding-progress-bar">
                <div
                  class="meter"
                  [style.width.%]="100 * getPercentageScore(row.value)"
                  [ngClass]="{
                    'good': getPercentageScore(row.value) > 0.8,
                    'bad': getPercentageScore(row.value) < 0.7
                  }"
                ></div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Submitted by" prop="user" [flexGrow]="1" [sortable]="false">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value.name }}</span>
            <i *ngIf="!value">Anonymous</i>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Comment" prop="comment" [flexGrow]="3" [sortable]="false"> </ngx-datatable-column>
        <ngx-datatable-column name="Conversation" prop="conversationId" [flexGrow]="1" [sortable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <a *ngIf="value" routerLink="/dashboard/inbox/all/conversations/{{value}}" target="_blank">
              View <ion-icon name="open-outline"></ion-icon>
            </a>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created" prop="createdAt" [flexGrow]="1" [sortable]="true">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            {{value | date:'MMM d yyyy'}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ion-card>
  </ion-col>

  <ion-col size="12">
    <h3 class="column-header">Per Assignee</h3>
  </ion-col>
  <ion-col size="12" *ngIf="!isLoading">
    <ion-card class="outline-card">
      <ngx-datatable
        class="material fullscreen"
        [columnMode]="ColumnMode.flex"
        [limit]="100"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [rows]="surveyScoresByAssignee"
        rowHeight="auto"
        [loadingIndicator]="isLoading"
      >
        <ngx-datatable-column name="Assignee" prop="assigneeName" [flexGrow]="1" [sortable]="true">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value">{{ value }}</span>
            <i *ngIf="!value">N/A</i>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ survey.type === 'nps' ? 'NPS score' : 'CSAT score' }}"
          prop="score"
          [flexGrow]="1"
          [sortable]="true"
        >
          <ng-template let-value="value" ngx-datatable-cell-template>
            <ion-badge color="light">{{ value }}</ion-badge>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Total responses" prop="totalResponses" [flexGrow]="1" [sortable]="true">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <ion-badge color="light">{{ value }}</ion-badge>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </ion-card>
  </ion-col>
</ion-row>
